var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"cart-card-block show p-0 col-12",attrs:{"id":"payment"}},[_c('div',{staticClass:"row align-item-center"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"iq-card"},[_vm._m(0),_c('div',{staticClass:"iq-card-body"},[_vm._m(1),_c('form',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v("Enter CVV: ")]),_vm._m(2),_c('button',{staticClass:"btn btn-primary text-white",attrs:{"type":"submit"},on:{"click":function($event){return _vm.$router.push({name: 'store.store-checkoutaddress'})}}},[_vm._v("Continue")])])]),_c('hr'),_vm._m(3),_c('hr'),_vm._m(4)])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iq-card-header d-flex justify-content-between"},[_c('div',{staticClass:"iq-header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Payment Options")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('img',{attrs:{"src":require("@/assets/images/icon/cart.png"),"alt":"","height":"40","width":"50"}}),_c('span',[_vm._v("US Unlocked Debit Card 12XX XXXX XXXX 0000")])]),_c('span',[_vm._v("Nik John")]),_c('span',[_vm._v("28/2020")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cvv-input ml-3 mr-3"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-lists"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-radio"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"radio","id":"credit","name":"customRadio"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"credit"}},[_vm._v(" Credit / Debit / ATM Card")])]),_c('div',{staticClass:"custom-control custom-radio"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"radio","id":"netbaking","name":"customRadio"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"netbaking"}},[_vm._v(" Net Banking")])]),_c('div',{staticClass:"custom-control custom-radio"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"radio","id":"emi","name":"emi"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"emi"}},[_vm._v(" EMI (Easy Installment)")])]),_c('div',{staticClass:"custom-control custom-radio"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"radio","id":"cod","name":"cod"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"cod"}},[_vm._v(" Cash On Delivery")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-card"},[_c('a',{attrs:{"href":"#"}},[_c('span',[_c('i',{staticClass:"ri-add-box-line mr-2 font-size-18"}),_vm._v("Add Gift Card")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"iq-card"},[_c('div',{staticClass:"iq-card-body"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Price Details")]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Price 3 Items")]),_c('span',[_c('strong',[_vm._v("$1000.00")])])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Delivery Charges")]),_c('span',{staticClass:"text-success"},[_vm._v("Free")])]),_c('hr'),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Amount Payable")]),_c('span',[_c('strong',[_vm._v("$1000.00")])])])])])])
}]

export { render, staticRenderFns }